<div class="d-flex align-items-center justify-content-between" [class.mb-4]="!isPDFExtract">
  <div class="d-flex align-items-center gap-10">
    <span class="material-symbols-outlined blue-color" *ngIf="!isPDFExtract">
      travel
    </span>
    <h3 class="mb-0 h3-label" [class.ml-5]="isPDFExtract">Itinerary Details</h3>
  </div>
  <div class="expand-icon-wrapper" (click)="isItineraryDetailsOpened = !isItineraryDetailsOpened" *ngIf="!isPDFExtract">
    <span class="material-symbols-outlined" [@rotate]="!isItineraryDetailsOpened">
      expand_less
    </span>
  </div>
</div>
<div *ngFor="let flight of flights; let index = index; first as first;"
     [class.opacity-50]="flight.deprecated"
     [@collapse]="!isItineraryDetailsOpened">
  <div class="mx-5 mb-2 d-flex align-items-end justify-content-between" [class.mt-5]="!first">
    <div class="d-flex gap-40 align-items-end">
      <div class="d-flex gap-10" style="min-width: 110px">
        <span class="flight-route stretch-wrapper" [appTooltip]="{airportCode: flight.departure?.airportCode, terminalName: flight.departure?.terminalName}">
          {{ flight.departure?.airportCode }}
        </span>
        <span class="flight-route">
          {{isPDFExtract ? '-' : '→'}}
        </span>
        <span class="flight-route" [appTooltip]="{airportCode: flight.arrival?.airportCode, terminalName: flight.arrival?.terminalName}">
          {{ flight.arrival?.airportCode }}
        </span>
      </div>
      <div *ngIf="flight.duration">
        <span class="text-uppercase gray-700-color fs-12">Travel time: </span>
        <span class="fs-14">{{ flight.duration | parseDuration }}</span>
      </div>
      <div *ngIf="fareNames[index] && fareNames[index][0]?.fareBasisCode">
        <span class="text-uppercase gray-700-color fs-12">Fare Basis Code: </span>
        <span class="fs-14">{{fareNames[index][0]?.fareBasisCode}}</span>
      </div>
    </div>
    <div *ngIf="!isPDFExtract">
      <app-baggage-allowance-info
        [baggageAllowance]="flight.baggageAllowance"
        [passengers]="passengers"
        [isPdf]="isPDFExtract">
      </app-baggage-allowance-info>
    </div>
  </div>
  <div *ngIf="isPDFExtract" class="ml-5 mt-3">
    <app-baggage-allowance-info
      [baggageAllowance]="flight.baggageAllowance"
      [passengers]="passengers"
      [isPdf]="isPDFExtract">
    </app-baggage-allowance-info>
  </div>
  <div class="table-responsive">
    <table class="table table-default mb-0" [class.pdf-table]="isPDFExtract">
      <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col" [style.width.px]="isPDFExtract ? '180' : ''">O&D</th>
        <th scope="col" class="text-nowrap">Departure</th>
        <th scope="col">Arrival</th>
        <th scope="col">Duration</th>
        <th scope="col" class="text-nowrap">Flight #</th>
        <th scope="col">Class</th>
        <th scope="col">Fare</th>
        <th scope="col">Aircraft</th>
        <th scope="col" *ngIf="!isPDFExtract">Status</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let flightSegment of flight.segments; index as segIdx">
        <td>
          <app-airline-img
            [airlineID]="flightSegment.operatingCarrier.airlineID"
            [aircraftCode]="flightSegment.equipment.aircraftCode"
            [title]="(flightSegment.operatingCarrier.airlineID | getAirline) + ' - ' + flightSegment.operatingCarrier.name ">
          </app-airline-img>
        </td>
        <td>
          <div class="text-nowrap" *ngIf="!isPDFExtract">
            <div class="d-flex gap-3">
              <span [appTooltip]="{airportCode: flightSegment.originDestination.departure?.airportCode, terminalName: flightSegment.originDestination.departure?.terminalName}">
                {{flightSegment.originDestination.departure.airportCode}}
              </span>
              <span>→</span>
              <span [appTooltip]="{airportCode: flightSegment.originDestination.arrival?.airportCode, terminalName: flightSegment.originDestination.arrival?.terminalName}">
                {{flightSegment.originDestination.arrival.airportCode}}
              </span>
            </div>
          </div>

          <div class="d-flex align-items-center flex-column" *ngIf="isPDFExtract">
            <span>
              {{flightSegment.originDestination.departure.airportCode}}
            </span>
            <span>({{flightSegment.originDestination.departure.airportName}})</span>
            <span>-</span>
            <span>{{flightSegment.originDestination.arrival.airportCode}}</span>
            <span>({{flightSegment.originDestination.arrival.airportName}})</span>
          </div>

          <ng-container *ngIf="flightSegment.detail.stopLocations?.length">
            <div class="stop"
                 *ngFor="let stop of flightSegment.detail.stopLocations">
              {{stop.airportCode}} (Stop)
            </div>
          </ng-container>
        </td>
        <td>
          <div class="d-flex align-items-center gap-2 text-nowrap" *ngIf="!isPDFExtract">
            <span>{{(flightSegment.originDestination.departure.date | dateFormat) | async}} - {{flightSegment.originDestination.departure.time}}h</span>
            <ng-container *ngIf="flightSegment.originDestination.departure.terminalName">
              <span class="material-icons fs-2">
                circle
              </span>
              <span>
                T {{flightSegment.originDestination.departure.terminalName}}
              </span>
            </ng-container>
          </div>

          <div class="d-flex flex-column align-items-center" *ngIf="isPDFExtract">
            <span>{{(flightSegment.originDestination.departure.date | dateFormat) | async}}</span>
            <span>{{flightSegment.originDestination.departure.time}}h</span>
            <span class="text-nowrap">
              Terminal: {{flightSegment.originDestination.departure.terminalName || '-'}}
            </span>
          </div>

          <ng-container *ngIf="flightSegment.detail.stopLocations?.length">
            <div class="stop"
                 *ngFor="let stop of flightSegment.detail.stopLocations">
              <span>{{(stop.departureDate | dateFormat) | async}} - {{stop.departureTime}}h</span>
            </div>
          </ng-container>
        </td>
        <td>
          <div class="d-flex align-items-center gap-2 text-nowrap" *ngIf="!isPDFExtract">
            <span>{{(flightSegment.originDestination.arrival.date | dateFormat) | async}} - {{flightSegment.originDestination.arrival.time}}h</span>
            <ng-container *ngIf="flightSegment.originDestination.arrival.terminalName">
              <span class="material-icons fs-2">
                circle
              </span>
              <span>
                T {{flightSegment.originDestination.arrival.terminalName}}
              </span>
            </ng-container>
          </div>

          <div class="d-flex flex-column align-items-center" *ngIf="isPDFExtract">
            <span>{{(flightSegment.originDestination.arrival.date | dateFormat) | async}}</span>
            <span>{{flightSegment.originDestination.arrival.time}}h</span>
            <span class="text-nowrap">
              Terminal: {{flightSegment.originDestination.arrival.terminalName || '-'}}
            </span>
          </div>

          <ng-container *ngIf="flightSegment.detail.stopLocations?.length">
            <div class="stop"
                 *ngFor="let stop of flightSegment.detail.stopLocations">
              <span>{{(stop.arrivalDate | dateFormat) | async}} - {{stop.arrivalTime}}h</span>
            </div>
          </ng-container>
        </td>
        <td>
          <div>{{flightSegment.detail.duration ? (flightSegment.detail.duration | parseDuration) : '-'}}</div>
          <ng-container *ngIf="flightSegment.detail.stopLocations?.length">
            <div class="stop"
                 *ngFor="let stop of flightSegment.detail.stopLocations">
              <span>{{stop.duration ? (stop.duration | parseDuration) : ''}}</span>
            </div>
          </ng-container>
        </td>
        <td>
          <span container="body"
                popoverClass="operated-by-popover"
                [ngbPopover]="operatedByRowPopover"
                [triggers]="'hover'"
                placement="top"
                class="cursor-default">
            {{flightSegment.marketingCarrier ? ((flightSegment.marketingCarrier.airlineID + flightSegment.marketingCarrier.flightNumber) | formatFlightNumber) : ''}}
          </span>
        </td>
        <ng-template #operatedByRowPopover>
          <app-operated-by-popover
            [operatingFlightNumber]="(flightSegment.operatingCarrier?.airlineID | getAirline) + ' ' + flightSegment.operatingCarrier?.flightNumber">
          </app-operated-by-popover>
        </ng-template>
        <td>{{flightSegment.detail.classOfService.code}}</td>
        <td>
          <div [ngbPopover]="flightSegment.detail.classOfService?.fare?.marketingName || ''"
               popoverClass="popover-wp"
               [triggers]="'hover'"
               placement="top"
               container="body"
               class="cursor-default">
            {{flightSegment.detail.classOfService.cabinDesignator}}
          </div>
        </td>
        <td>{{flightSegment.equipment.aircraftCode || '-'}}</td>
        <td *ngIf="!isPDFExtract">
          <div *ngIf="flightSegment?.detail?.segmentType"
               class="segment-type"
               [ngbPopover]="segmentType"
               popoverClass="popover-wp"
               [triggers]="'hover'"
               placement="top"
               container="body"
               [style.background-color]="flightSegment.detail.segmentType | getColor">
            {{flightSegment.detail.segmentType}}
          </div>
          <ng-template #segmentType>
            <ng-container *ngIf="flightSegment?.detail?.segmentTypeDefinition; else defaultSegmentTypeDefinition">
              <div>
                <div> {{flightSegment?.detail?.segmentType}} ({{flightSegment?.detail?.segmentTypeDefinition}})</div>
              </div>
            </ng-container>
          </ng-template>
          <ng-template #defaultSegmentTypeDefinition>
            <div [ngSwitch]="flightSegment?.detail?.segmentType">
              <div *ngSwitchCase="'HK'">{{flightSegment?.detail?.segmentType}} (confirmed)</div>
              <div *ngSwitchCase="'UN'">{{flightSegment?.detail?.segmentType}} (unconfirmed)</div>
              <div *ngSwitchDefault>{{flightSegment?.detail?.segmentType}} (other status)</div>
            </div>
          </ng-template>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <ng-content></ng-content>
</div>

